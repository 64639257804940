import React from 'react';
import dayjs from 'dayjs';
import { FormHelperText } from '@material-ui/core';
import DateFnsUtils from '@date-io/dayjs';
import { Controller } from 'react-hook-form';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { formatDateInput } from '../utils/dateFormatter';

export const DateInput = ({
  object = {},
  label,
  errors,
  control,
  name,
  placeholder,
  format = 'DD/MM/YY',
  reff,
  disabled = false,
  textFieldStyles,
  setValue,
  setError,
  onKeyDown,
  inputProps,
  formHelperStyles,
  variant = 'standard',
  defaultValue,
  customDefault,
  customDefaultFormat,
  customHintMessage,
  ...props
}) => {
  return (
    <Controller
      rules={{ required: true }}
      defaultValue={
        customDefault
          ? dayjs(customDefault, customDefaultFormat || 'DD.MM.YYYY HH:mm')
          : formatDateInput(defaultValue || object?.[name], format === 'DD/MM/YYYY HH:mm')
      }
      control={control}
      name={name}
      render={({ onChange, value, ref }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <>
            {format === 'DD.MM.YYYY HH:mm' ? (
              <KeyboardDateTimePicker
                {...props}
                style={{ ...textFieldStyles }}
                value={value}
                fullWidth
                clearable
                error={errors[name]}
                label={label}
                inputRef={(e) => {
                  ref.current = e;
                  if (reff) reff.current = e;
                }}
                onKeyDown={onKeyDown}
                autoOk
                onChange={(e) => {
                  onChange(e);
                }}
                disabled={disabled}
                inputVariant={variant}
                id={name}
                format="DD.MM.YYYY HH:mm"
                placeholder={`${placeholder || ''} ${format}`}
                onError={(e) => (setError && e ? setError(true) : !e && setError(false))}
                invalidDateMessage="Datum nije unesen u ispravnom obliku"
                InputProps={{ ...inputProps }}
                KeyboardButtonProps={{
                  className: 'date-picker-btn',
                }}
              />
            ) : (
              <KeyboardDatePicker
                {...props}
                style={{ ...textFieldStyles }}
                value={value}
                fullWidth
                clearable
                error={errors[name]}
                label={label}
                inputRef={(e) => {
                  ref.current = e;
                  if (reff) reff.current = e;
                }}
                onKeyDown={onKeyDown}
                autoOk
                onChange={(e) => {
                  onChange(formatDateInput(e, format === 'DD/MM/YYYY HH:mm'));
                }}
                disabled={disabled}
                inputVariant={variant}
                id={name}
                format={format || 'DD/MM/YY'}
                placeholder={`${placeholder || ''} (${format})`}
                onError={(e) => (setError && e ? setError(true) : !e && setError(false))}
                invalidDateMessage="Datum nije unesen u ispravnom obliku"
                maxDateMessage="Datum nije unesen u ispravnom obliku!"
                inputProps={{ ...inputProps }}
                KeyboardButtonProps={{
                  className: 'date-picker-btn',
                }}
              />
            )}
            {errors[name] ? (
              <FormHelperText style={{ ...formHelperStyles }} error>
                {errors[name].message}
              </FormHelperText>
            ) : (
              <FormHelperText style={{ ...formHelperStyles }}>{customHintMessage}</FormHelperText>
            )}
          </>
        </MuiPickersUtilsProvider>
      )}
    />
  );
};
